export default {
  data () {
    return {
      tableHeight: 0
    }
  },
  methods: {
    // 获取高度
    getHeight () {
      this.$nextTick(() => {
        this.tableHeight =
          (document.documentElement.clientHeight || document.body.clientHeight) -
          this.offsetTop(this.$refs.tableList && this.$refs.tableList.$el) -
          40;
      })
    },
    offsetTop (element) {
      let top = element && element.offsetTop;
      let parent = element && element.offsetParent;
      while (parent !== null) {
        top += parent.offsetTop;
        parent = parent.offsetParent;
      }
      return top;
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.getHeight();
    });
    window.onresize = () => {
      this.getHeight();
    };
  }
}
