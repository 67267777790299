<template>
  <div>
    <div class="updateBox" v-show="showList">
      <div class="headFlexBox">
        <div class="headBoxText">公告管理</div>
        <div>
          <el-button type="text" icon="el-icon-plus" @click="handleAdd">新增</el-button>
        </div>
      </div>
      <el-card class="box-card">
        <div class="account">
          <span class="cus_span">类型：</span>
          <el-select v-model="type" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </div>
        <div class="screenBtn">
          <el-button type="primary" icon="icon iconfont icon-sousuo" @click="handleSearch">搜索</el-button>
        </div>
        <tablePage
          :tableData="tableData"
          :currentPage="currentPage"
          :height="tableHeight"
          :isHeadColor="true"
          :total="total"
          @current-change="currentChange"
          ref="tableList"
          v-loading="loading"
        >
          <el-table-column prop="announce_id" min-width="80px" label="ID"></el-table-column>
          <el-table-column prop="title" min-width="180px" label="标题"></el-table-column>
          <el-table-column label="显示">
            <template slot-scope="{row}">
              <el-switch
                v-model="row.published"
                active-icon-class="icon iconfont icon-kai"
                inactive-icon-class="icon iconfont icon-guan"
                :active-value="1"
                :inactive-value="0"
                @change="handleStatus($event,row.announce_id)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="created_at" min-width="120px" label="创建时间"></el-table-column>
          <el-table-column prop="updated_at" min-width="120px" label="更新时间"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{row}">
              <el-button type="text" @click="handleEdit(row)">编辑</el-button>
              <el-button class="cus_button" type="text" @click="handleDel(row.announce_id)">删除</el-button>
            </template>
          </el-table-column>
        </tablePage>
      </el-card>
      <div class="dialogDelBox">
        <el-dialog title="删除提醒" :visible.sync="dialogDel" :show-close="false">
          <div class="dialogDelText">您确定要删除此公告吗?</div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogDel = false">取 消</el-button>
            <el-button class="form_btn" type="primary" @click="noticeDel">确 定</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import tableHeight from "mixins/tableHeight";
import api from "@/api/guildManageapi";
import tablePage from "components/tablePage";
const options = [
  { key: 0, value: "全部" },
  { key: 1, value: "咨询" },
  { key: 2, value: "小程序公告" },
  { key: 3, value: "紧急通知" },
];
export default {
  name: "profitPage",
  components: {
    tablePage,
  },
  mixins: [tableHeight],
  data() {
    return {
      dialogDel: false,
      loading: true,
      options,
      currentPage: 1,
      total: 0,
      tableData: [],
      type: 0,
      switchValue: 1,
    };
  },
  computed: {
    showList() {
      // this._getList()
      return this.$route.name == "noticepage";
    },
  },
  watch: {
    showList: {
      handler: function (val) {
        if (val == true) {
          this.loading = true;
          this._getList();
        }
      },
      immediate: true,
    },
  },
  created() {
    this._getList();
  },
  methods: {
    //获取后台table数据
    async _getList() {
      let params = { page: this.currentPage, type: this.type, is_publish: 2 };
      const data = await api.getNoticeList(params);
      if (data.status_code === 200) {
        this.tableData = data.message;
        this.total = data.meta.pagination.total;
        this.loading = false;
      }
    },
    //点击页数切换
    currentChange(val) {
      this.currentPage = val;
      this.loading = true
      this._getList();
    },
    handleAdd() {
      this.$router.push({
        path: "/backstage/noticeadd",
      });
    },
    handleEdit(row) {
      this.$router.push({
        path: "/backstage/noticeedit",
        query: {
          announce_id: row.announce_id,
        },
      });
    },
    //删除公告按钮
    handleDel(id) {
      this.dialogDel = true;
      this.id = id;
    },
    //删除公告接口
    async noticeDel() {
      this.loading = true;
      let id = this.id;
      const data = await api.noticeDelApi({ id: id });
      if (data.status_code === 200) {
        this.$message({
          message: data.message,
          type: "success",
        });
        this._getList();
      }
    },
    handleSearch() {
      this.loading = true;
      this.currentPage = 1;
      this._getList();
    },
    handleStatus(top, id) {
      // console.log({e,id});
      this._setTop({ top, id });
    },
    //是否置顶
    async _setTop({ top, id }) {
      console.log({ top, id });
      const { status_code, message } = await api.setTopApi({ top, id });
      if (status_code === 200) {
        this._getList();
        this.$message({
          message: message,
          type: "success",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./css/headCss.less";
@import "./css/cusDialog.less";
.cus_span {
  font-size: 14px;
  color: #333333;
  display: inline-block;
}
.updateBox /deep/ .iconfont {
  font-size: 14px;
  margin-right: 4px;
}
.updateBox /deep/ .el-button--primary {
  border-radius: 2px;
}
// .updateBox /deep/ .el-table thead {
//   color: #333333;
//   font-weight: 400 !important;
// }
.updateBox /deep/ .el-card.is-always-shadow {
  height: 630px;
}
.account {
  height: 58px;
  line-height: 58px;
}
.screenBtn {
  height: 58px;
  line-height: 58px;
  margin-bottom: 10px;
}
.cus_button {
  color: #666666;
}
.updateBox /deep/.el-dialog {
  margin-top: 0vh !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}
.updateBox /deep/ .el-input__inner {
  height: 34px;
  line-height: 34px;
  // color: #999999;
}
.updateBox /deep/ .el-switch.is-checked .el-switch__core {
  border-color: rgba(255, 255, 255, 0);
  background: linear-gradient(270deg, #fe61a2 0%, #f39064 100%);
}
.updateBox /deep/ .el-switch__label * {
  line-height: 20px;
}
.updateBox /deep/ .el-switch__label {
  height: 18px;
}
.updateBox /deep/ .el-switch__label.is-active {
  color: #ffffff;
  z-index: 5;
}
.updateBox /deep/ .el-switch__label--right {
  margin-left: -35px;
  // z-index: 5;
}
.updateBox /deep/ .el-switch__label--left {
  margin-right: 0px;
}
.updateBox /deep/.el-switch__label {
  color: #ffffff;
}
.updateBox /deep/ .icon-guan {
  position: absolute;
  right: -13px;
  top: 1px;
}
.updateBox /deep/ .cus_button span{
  border-left: 1px solid #e3e3e3;
  padding-left: 12px;
}
</style>